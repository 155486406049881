<template>
  <div gp-page-template>
    <!--    <SubHeader v-if="isShowSubHeader" :title="title" @back="back" :is-back-button="isBackButton" />-->
    <div class="page-content-header">
      <slot name="header" :is-back-button="isBackButton"></slot>
    </div>
    <div class="page-content-body" v-if="isView">
      <slot name="content" :success="success" :error="error" :loading="loading" />
    </div>
    <div class="page-result-view" v-if="isResult">
      <ResultPage v-if="isResult" :message="message" :params="params" :type="status" :root-route="rootRoute" :structure="structure" @reset="reset" />
    </div>
    <LoadingPage v-if="isLoading" :message="loadingMessage" />

  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import ResultPage from '@/views/components/gg-pass/ResultPage.vue';
import LoadingPage from '@/views/components/gg-pass/LoadingPage.vue';
import baseTemplate from '@/mixins/baseTemplate';
import Specific from '@shared/types/Specific';
import { STATUS, STEP } from '@/constants/base/my-page';
import SubHeader from '@/views/components/gg-pass/SubHeader.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { sleep } from '@shared/utils/commonUtils.mjs';
export default {
  name: 'GpPageTemplate',
  components: { LoadingPage, ResultPage },
  props: {
    title: { type: String, default: '' },
    rootRoute: { type: String, default: null },
    structure: Specific,
    app: { type: String, default: '' },
  },
  data() {
    return {
      status: STATUS.VIEW,
      message: 'Successfully message',
      params: null,
      isLoading: false,
      step: STEP.DEFAULT,
    };
  },
  computed: {
    reCheckTarget: state('user', 'reCheckTarget'),
    site: state('env', 'site'),
    isResult() {
      return this.status === STATUS.SUCCESS || this.status === STATUS.ERROR;
    },
    isView() {
      return this.status === STATUS.VIEW;
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    isBackButton() {
      if(this.reCheckTarget) return false;
      if (this.isTwoAce && this.app === 'rg') {
        return this.$rg.step === STEP.INPUT;
      }
      return (this.isView && !this.isLoading && this.$route.name !== 'NpComplete');
    },
    loadingMessage() {
      return this.$t('progress');
    }

  },
  methods: {
    startLoading() {
      this.isLoading = true;
      this.message = 'Progress';
    },
    endLoading() {
      this.isLoading = false;
      this.message = 'Progress';
    },

    setResultData({ type, message, params }) {
      this.status = type;
      this.message = message;
      this.params = params;
    },

    async success(message, params) {
      this.setResultData({ type: STATUS.SUCCESS, message, params });
    },

    error(message, params) {
      this.setResultData({ type: STATUS.ERROR, message, params });
    },

    loading(on) {
      if (on) this.startLoading();
      else this.endLoading();
    },
    back() {
      this.$emit('back');
    },
    reset() {
      this.isLoading = false;
      this.status = STATUS.VIEW;
    }
  },

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-page-template] { .flex(); .flex-dc(); flex: 1; .w(100%);
  .page-content-header {.p(0, 0, 0, 0);}
  .page-content-body {flex: 1; .flex();}
  .page-result-view {flex: 1; .flex(); .rel();}
}
</style>